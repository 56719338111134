import {gql} from '@apollo/client'

export const GET_ALL_POLICIES_API = gql`
  query allPolicies(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
  ) {
    allPolicies(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
    ) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          policyName
          applicants
          members
          dependents
          stateDocs
          paymentStatus
          FFM
          NPN
          typeSale
          effectiveDate
          marketplaceId
          memberId
          CMSPlanId
          planName
          metalLevel
          policyTotalCost
          taxCredit
          premium
          percentageValue
          nominalValue
          status
          user {
            id
            firstName
            lastName
            email
            cellphone
          }
          typePolicy {
            id
            typePolicy
          }
          carrier {
            id
            name
          }
          stateEU {
            name
            id
          }
          memberPolicySet {
            edges {
              node {
                id
                applicant
                firstName
                lastName
                phone
                rol
                physicalAddress
              }
            }
          }
          payment {
            id
            bankName
          }
        }
      }
    }
  }
`
export const GET_ONE_POLICY_API = gql`
  query policiesById($id: String) {
    policiesById(id: $id) {
      id
      policyName
      applicants
      members
      dependents
      stateDocs
      paymentStatus
      FFM
      NPN
      typeSale
      effectiveDate
      marketplaceId
      memberId
      CMSPlanId
      planName
      metalLevel
      policyTotalCost
      taxCredit
      premium
      percentageValue
      nominalValue
      status
      completedDate
      canceledDate
      updated
      user {
        id
        firstName
        lastName
      }
      typePolicy {
        id
        typePolicy
      }
      carrier {
        id
        name
      }
      stateEU {
        name
        id
      }
      lead {
        id
        name
      }
      memberPolicySet {
        edges {
          node {
            id
            applicant
            firstName
            created
            updated
            rol
            middleName
            lastName
            secondLastName
            preferredLenguage
            dateBirth
            gender
            emailAddress
            phone
            maritalStatus
            countryBirth
            weight
            height
            immigrationStatus
            immigrationStatusCode
            uscis
            passport
            ssn
            ssnIssuedDate
            greenCard
            greenCardIssuedDate
            greenCardExpirationDate
            workPermitCard
            workPermitIssuedDate
            workPermitExpirationDate
            driverLicense
            driverLicenseExpirationDate
            physicalAddress
            mailingAddress
            sourceOfIncome1
            sourceOfIncome2
            sourceOfIncome3
          }
        }
      }
      payment {
        id
        recurrentPayment
        typeCard
        cardCompany
        cardNumber
        expirationMonth
        expirationDay
        cvvCsv
        firstPaymentDate
        bankAccount
        bankName
        ABARouting
        accountNumber
        preferredPayment
        billingAddress
        paymentHolder
        address1
        address2
        city
        state
        zipCode
        policy {
          id
        }
      }
      extraInfo {
        id
        primaryDoctorInfo
        medicinesNeeded
        infoExtra
        cancellationDate
        specialEnrollmentDate
        specialEnrollmentReason
      }
      attachmentsSet {
        edges {
          node {
            id
            created
            name
            expirationDate
            attachments
            comments
            memberPolicy {
              id
              firstName
              lastName
            }
            documentTag {
              id
              tagName
            }
          }
        }
      }
    }
  }
`

export const GET_DOCUMENTS_POLICY = gql`
  query allAttachmentsTags(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
  ) {
    allAttachmentsTags(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
    ) {
      edges {
        node {
          id
          tagName
        }
      }
    }
  }
`

export const GET_ALL_LOGS = gql`
  query policyLogsByPolicyId($policyId: Int!) {
    policyLogsByPolicyId(policyId: $policyId) {
      id
      oldValue
      newValue
      updatedAt
      user {
        id
        firstName
        lastName
      }
      changes
    }
  }
`
