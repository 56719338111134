export const CREATE_EXCEL_ADD_COMMISSION_STR = `
  mutation policyCreateCarrierpaymentinfo($excelFile: Upload!) {
    policyCreateCarrierpaymentinfo(excelFile: $excelFile) {
      success
      message
      errors {
        field
        message
      }
    }
  }
`
