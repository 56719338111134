import {useLazyQuery} from '@apollo/client'
import React, {useEffect, useRef, useState} from 'react'
import {Form, Button, Col, Modal, Row, Alert, Spinner} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {GET_ZIP_INFO} from '../../../gql/queries/leadsQuery'
import {IResZipCode} from '../../../types/quoteTypes'
import {toast} from 'react-toastify'

interface IModalAddQProps {
  isOpen: boolean
  onClose: () => void
  info?: {
    id: string
    dateBirthLead: string | null | undefined
  }
}

export const ModalAddQoute: React.FC<IModalAddQProps> = ({isOpen, onClose, info}) => {
  const [gqlGetMarket, {data, loading, error}] = useLazyQuery<IResZipCode>(GET_ZIP_INFO, {
    fetchPolicy: 'no-cache',
  })
  const refControl = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    queryType: 'BUSCAR_PLANES',
    year: 2024,
    householdIncome: '0',
    aptcEligible: false,
    hasMec: false,
    dob: '',
    isPregnant: false,
    isParent: false,
    usesTobacco: false,
    gender: 'Male',
    hasMarriedCouple: false,
    market: 'Individual',
    countyfips: '', //17033
    state: '', //IL
    zipCode: '', //62466
    limit: 10,
    offset: 0,
    order: 'asc',
    utilizationLevel: 'Low',
  })

  /* useEffect(() => {
    if (!refControl.current) return
    const handleBlur = () => {
      console.log('blublur')
    }
    const input = refControl.current
    input.addEventListener('blur', handleBlur)

    return () => {
      input.removeEventListener('blur', handleBlur)
    }
  }, []) */

  useEffect(() => {
    if (!data) return
    if (data.zipcodeByZip) {
      setFormData((p) => ({
        ...p,
        countyfips: data.zipcodeByZip.countyFips,
        state: data.zipcodeByZip.stateId,
      }))
    }
  }, [data])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (info?.dateBirthLead) {
      setFormData((p) => ({...p, dob: info.dateBirthLead || ''}))
    }
  }, [info])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const dataStr = JSON.stringify({
      ...formData,
      householdIncome: formData.householdIncome.replaceAll(',', ''),
      dob: formData.dob || undefined,
    })
    const decode = window.btoa(dataStr)
    navigate(`/quote/${encodeURIComponent(decode)}`)
    onClose()
    /*  gqlGetMarket({
      variables: {
        ...formData,
        householdIncome: formData.householdIncome.replaceAll(',', ''),
        dob: formData.dob || undefined,
      },
    }) */
  }

  return (
    <Modal show={isOpen} onHide={onClose} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Quote
          {loading && <Spinner animation='border' size='sm' className='ms-2' />}
        </Modal.Title>
      </Modal.Header>
      {/* {page === 'form' ? ( */}
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant='primary' className='my-5 d-flex align-items-center gap-2'>
            <span>
              <i className='bi bi-exclamation-circle fs-1' style={{color: 'inherit'}} />
            </span>
            Please fill in the necessary information below to receive a quote for insurance
            plans.asdf
          </Alert>
          <Row className='mt-4'>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Household Income</Form.Label>
                <Form.Control
                  type='text'
                  name='householdIncome'
                  value={formData.householdIncome}
                  onChange={(e) => {
                    const {value} = e.target
                    const noComma = value.replaceAll(',', '')
                    if (noComma !== '' && isNaN(Number(noComma))) return
                    const str = Intl.NumberFormat('en-US').format(Number(noComma))
                    setFormData((p) => ({...p, householdIncome: str}))
                  }}
                  required
                />
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group>
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type='date' name='dob' value={formData.dob} onChange={handleChange} />
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  required
                  as='select'
                  name='gender'
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='isPregnant'
                  id='isPregnant'
                  checked={formData.isPregnant}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='isPregnant'>
                  Is Pregnant ({formData.isPregnant ? 'YES' : 'NO'})
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='isParent'
                  id='isParent'
                  checked={formData.isParent}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='isParent'>
                  Is Parent ({formData.isParent ? 'YES' : 'NO'})
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='aptcEligible'
                  id='aptcEligible'
                  checked={formData.aptcEligible}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='aptcEligible'>
                  APTC Eligible ({formData.aptcEligible ? 'YES' : 'NO'})
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='usesTobacco'
                  id='usesTobacco'
                  checked={formData.usesTobacco}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='usesTobacco'>
                  Uses Tobacco ({formData.usesTobacco ? 'YES' : 'NO'})
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='hasMarriedCouple'
                  id='hasMarriedCouple'
                  checked={formData.hasMarriedCouple}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='hasMarriedCouple'>
                  Has Married Couple ({formData.hasMarriedCouple ? 'YES' : 'NO'})
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <Form.Group className='d-flex'>
                <Form.Check
                  type='switch'
                  // label='Is Pregnant'
                  name='hasMec'
                  id='hasMec'
                  checked={formData.hasMec}
                  onChange={handleChange}
                />
                <Form.Label htmlFor='hasMec'>Has MEC ({formData.hasMec ? 'YES' : 'NO'})</Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-4'>
            {/* <Col lg={4}>
                <Form.Group>
                  <Form.Label>Market</Form.Label>
                  <Form.Control
                    as='select'
                    name='market'
                    value={formData.market}
                    onChange={handleChange}
                  >
                    <option value='Individual'>Individual</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}
            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  ref={refControl}
                  type='text'
                  name='zipCode'
                  value={formData.zipCode}
                  onChange={handleChange}
                  onBlur={(e) => {
                    e.preventDefault()
                    console.log('blur')
                    if (formData.zipCode.length < 3) return
                    setFormData((p) => ({...p, countyfips: '', state: ''}))

                    gqlGetMarket({
                      variables: {
                        zipCode: formData.zipCode,
                      },
                    })
                  }}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>County FIPS</Form.Label>
                <Form.Select
                  value={formData.countyfips}
                  onChange={(e) => setFormData((p) => ({...p, countyfips: e.target.value}))}
                >
                  <option value=''>Select county FIPS</option>
                  {data &&
                    data?.zipcodeByZip?.countyNamesAll?.split('|').map((str, idx) => (
                      <option key={str} value={data?.zipcodeByZip?.countyFipsAll?.split('|')[idx]}>
                        {str} - {data?.zipcodeByZip?.countyFipsAll?.split('|')[idx]}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col style={{display: 'none'}} lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>County FIPS</Form.Label>
                <Form.Control
                  type='text'
                  name='countyfips'
                  value={formData.countyfips}
                  required
                  // onChange={handleChange}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col style={{display: 'none'}} lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type='text'
                  name='state'
                  value={formData.state}
                  required
                  // onChange={handleChange}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg={3} md={6} sm={12} xs={12}>
              <Form.Group>
                <Form.Label>Order</Form.Label>
                <Form.Control
                  as='select'
                  name='order'
                  value={formData.order}
                  onChange={handleChange}
                >
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* <Row className='mt-4'>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type='number'
                    name='limit'
                    value={formData.limit}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Offset</Form.Label>
                  <Form.Control
                    type='number'
                    name='offset'
                    value={formData.offset}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' type='button' onClick={onClose}>
            Close
          </Button>
          <Button variant='primary' type='submit'>
            Search
          </Button>
        </Modal.Footer>
      </Form>
      {/* ) : (
        <>
          <Modal.Body style={{backgroundColor: '#f8f8f8'}}>
            <div style={{minHeight: '600px'}}>
              <div className='mb-4'>
                <button
                  className='d-flex align-items-center gap-2 bg-light-primary rounded'
                  style={{border: 0}}
                  onClick={() => {
                    setPage('form')
                    setResult(null)
                  }}
                >
                  <i className='bi bi-arrow-left' style={{color: 'inherit'}} />
                  Volver
                </button>
              </div>
              {result && <ResultsAddQuote result={result} />}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Acept</Button>
          </Modal.Footer>
        </>
      )} */}
    </Modal>
  )
}
