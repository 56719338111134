import {useQuery} from '@apollo/client'
import React, {useEffect} from 'react'
import {GET_ALL_LOGS} from '../../../gql/queries/policiesApiQueries'
import {toast} from 'react-toastify'
import {Accordion, Spinner} from 'react-bootstrap'

interface IActivityProps {
  id: string
}

interface IResLogsPolicy {
  policyLogsByPolicyId: {
    id: string
    oldValue: string
    newValue: string
    updatedAt: string
    user: {
      id: string
      firstName: string
      lastName: string
    }
    changes: string[]
  }[]
}

export const ActivityTab: React.FC<IActivityProps> = ({id}) => {
  const {data, loading, error} = useQuery<IResLogsPolicy>(GET_ALL_LOGS, {
    variables: {policyId: id},
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
          backgroundColor: '#fff',
          marginBottom: '20px',
          borderRadius: '4px',
          padding: '8px 10px',
        }}
      >
        <div className='kt-heading pb-2 custom-border-brand d-flex align-items-center justify-content-between'>
          <span>Activity</span>
        </div>
        <div className='d-flex flex-center' style={{height: '40vh'}}>
          <Spinner animation='border' />
        </div>
      </div>
    )
  }

  if (data.policyLogsByPolicyId.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
          backgroundColor: '#fff',
          marginBottom: '20px',
          borderRadius: '4px',
          padding: '8px 10px',
        }}
      >
        <div className='kt-heading pb-2 custom-border-brand d-flex align-items-center justify-content-between'>
          <span>Activity</span>
        </div>
        <div className='d-flex flex-center' style={{height: '40vh'}}>
          <h4>There is no activity recorded at the moment</h4>
        </div>
      </div>
    )
  }

  const first = data.policyLogsByPolicyId[0]

  const restArr = data.policyLogsByPolicyId?.slice(1) || []

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxShadow: '0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)',
        backgroundColor: '#fff',
        marginBottom: '20px',
        borderRadius: '4px',
        padding: '8px 10px',
      }}
    >
      <div className='kt-heading pb-2 custom-border-brand d-flex align-items-center justify-content-between'>
        <span>Activity</span>
      </div>
      <Accordion defaultActiveKey={`${data.policyLogsByPolicyId.length}`}>
        {restArr.reverse().map((log, index) => {
          const {oldValue, newValue, updatedAt, user, changes} = log
          const oldData = oldValue ? JSON.parse(oldValue) : null
          const newData = JSON.parse(newValue)

          // Identificar diferencias
          let differences = Object.keys(newData).reduce<
            {field: string; oldValue: string; newValue: string}[]
          >((diff, key) => {
            if (!oldData || oldData[key] !== newData[key]) {
              diff.push({
                field: key,
                oldValue: oldData ? oldData[key] : 'N/A',
                newValue: newData[key],
              })
            }
            return diff
          }, [])

          differences = differences.filter((ob) => ob.field !== 'updated')

          return (
            <Accordion.Item
              eventKey={`${data.policyLogsByPolicyId.length - index}`}
              key={log.id}
              className='log-entry mb-4'
            >
              <Accordion.Header className='fw-bold'>
                Record #{data.policyLogsByPolicyId.length - index}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>Updated by:</strong> {user.firstName} {user.lastName} <br />
                  <strong>Date:</strong> {new Date(updatedAt).toLocaleString()} <br />
                  <strong>Changes:</strong> {changes.join(', ')}
                </p>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th className='fw-bold'>Field</th>
                      <th className='fw-bold'>Previous Value</th>
                      <th className='fw-bold'>New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {differences.length > 0 ? (
                      differences.map(({field, oldValue, newValue}, i) => (
                        <tr key={i}>
                          <td>{field}</td>
                          <td>{oldValue !== undefined ? oldValue : 'N/A'}</td>
                          <td>{newValue !== undefined ? newValue : 'N/A'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>There are no differences</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
        <Accordion.Item eventKey={`${1}`}>
          <Accordion.Header className='font-bold'>Record #{1}</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong>Updated by:</strong> {first.user.firstName} {first.user.lastName} <br />
              <strong>Date:</strong> {new Date(first.updatedAt).toLocaleString()} <br />
              <strong>Changes:</strong> {first.changes.join(', ')}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
