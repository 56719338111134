import {ChangeEvent, useEffect, useRef} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {CREATE_EXCEL_ADD_COMMISSION_STR} from '../../gql/mutations/commissionsMutations'
import {useUploadFile} from '../../hooks/useUploadFile'
import {IResAddExcelCommissions} from '../../types/commissionsType'
import {toast} from 'react-toastify'

export const CommissionsPage = () => {
  const [uploadFile, {data: dataFile, error: errorFile, loading: loadingFile}] =
    useUploadFile<IResAddExcelCommissions>()

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (errorFile) toast.error(`Error: ${errorFile}`)
  }, [errorFile])

  useEffect(() => {
    if (!dataFile) return
    if (dataFile.policyCreateCarrierpaymentinfo.success) {
      toast.success('File uploaded successfully')
      // refetch()
    } else {
      toast.error(`Error: ${dataFile.policyCreateCarrierpaymentinfo.message || 'something wrong!'}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFile])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]
    if (!file) return
    const formdata = new FormData()
    formdata.append('0', file, file.name)
    formdata.append('map', '{"0": ["variables.excelFile"]}')
    const obj = {
      query: CREATE_EXCEL_ADD_COMMISSION_STR,
      variables: {
        excelFile: null,
      },
    }
    formdata.append('operations', JSON.stringify(obj))
    uploadFile(formdata)
  }
  const handleButtonClick = () => {
    fileInputRef?.current?.click()
  }
  return (
    <div>
      <header className='d-flex align-items-center flex-column flex-md-row justify-content-between'>
        <h3>Commissions</h3>
        <div className='d-flex align-items-center  gap-3 flex-column flex-md-row'>
          {/* <Button
            className='w-100 w-md-auto'
            onClick={handleDownload}
            disabled={loadingExport}
            variant='info'
          >
            {loadingExport ? (
              <Spinner animation='border' size='sm' />
            ) : (
              <i className='bi bi-file-earmark-arrow-down fs-3' />
            )}
            Excel base
          </Button> */}
          <Button
            className='w-100 w-md-auto'
            variant='success'
            onClick={handleButtonClick}
            disabled={loadingFile}
          >
            {loadingFile ? (
              <Spinner animation='border' size='sm' />
            ) : (
              <i className='bi bi-file-earmark-arrow-up-fill fs-3' />
            )}
            Upload Excel
          </Button>
          <input
            type='file'
            ref={fileInputRef}
            style={{display: 'none'}}
            accept='.xlsx,.xls'
            onChange={handleFileChange}
          />
          {/* <Button
            className='w-100 w-md-auto'
            variant='primary'
            onClick={() => {
              onOpenAdd()
            }}
          >
            <i className='bi bi-plus fs-3' />
            Commission
          </Button> */}
        </div>
      </header>
    </div>
  )
}
